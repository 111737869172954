/* Vendors CSS */

/* Dashboard Layout */

@import "./src/assets/vendors/metismenu/css/metisMenu.min";

@import "./src/assets/vendors/perfect-scrollbar/css/perfect-scrollbar";

/* Form Select Enhancements */

@import "./src/assets/vendors/select2/select2.min";

/* File Uploads */

@import "./src/assets/vendors/dropzone/dropzone.min";

/* WYSIWYG Editor */

@import "./src/assets/vendors/summernote/summernote-lite.min";

/* Date Picker */

@import "./src/assets/vendors/tempus-dominus/tempus-dominus.min";

/* Form Validation */

@import "./src/assets/vendors/formvalidation/css/formValidation.min";

/* Data Tables */

@import "./src/assets/vendors/datatables/jquery.dataTables.min";

/* Notifications */

@import "./src/assets/vendors/toastr/toastr.min";